//baseURL: 'nameless-depths-16148.herokuapp.com'  //works with heroku, why not my own docker container??
// for reasons unknown, it DOESN"T WORK unless using heroku
// it can do GET but not POST requests, it gets stuck onloading
// ~~might be SSL issue, but even heroku should work without ssl? Or maybe not since it's a POST request. Not sure.
const constants = {
    //baseURL: 'azfriendzone-api.herokuapp.com'
    //baseURL: '192.168.86.83:4151'  //CORS failed on OPTIONS get, NS_ERROR_DOM_BAD_URI on POST 
    //baseURL: 'localhost:4151'      //CORS failed on OPTIONS get, NS_ERROR_DOM_BAD_URI on POST 
    //baseURL: 'fzapi.azfriend.us'  //NS_BINDING_ABORTED on POST, CORS failed on OPTIONS get, NS_ERROR_DOM_BAD_URI on POST    
    //    baseURL: 'https://fzapi.azfriend.us:8443'  //this is how we would ssl without port 443 (but then port number shows)
    baseURL: 'https://fzapi.azfriend.us'  //default ssh of 443
};

export default constants;