import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//import Form from "react-bootstrap/Form";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import constants from "../components/constants";

import InfoPage from "./InfoPage";
import ListedItemsPage from "./ListedItemsPage";
import FriendMatchCreateMobile from "./FriendMatchCreateMobile";

const NWRHub: React.FC<any> = (props) => {
  const [userObj, setUserObj] = useState({
    id: -1,
    secret_username: "",
    contact_by: "",
    location: "",
    is_admin: 0,
  } as any);

  const {
    isMobile = "true",
    groupDesc = "",
    cbSetGroupDesc = null,
    manualRefresh = false,
    cbToggleToManualRefresh = null,
  } = props;

  /////////////////

  const [hasSecretUsername, setHasSecretUsername] = useState("false");

  const [infoText, setInfoText] = useState(false);

  let { groupId }: any = useParams();

  const submitToGetUserInfo = (
    secretUsername0 = "",
    formikSetFieldValue: any
  ) => {
    //add one entry at a time, as user adds it
    let theUrl = `${constants.baseURL}/nwrb/getuser`;

    if (secretUsername0.trim() != "") {
      fetch(theUrl, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          passkey: "just the uinfo this time por favor",
          groupId: groupId,
          secretUsername: secretUsername0,
        }),
      })
        .then((response) => response.json())
        .then((resultData) => {
          setUserObj({
            id: resultData.id,
            secret_username: secretUsername0,
            contact_by: resultData.contact_by,
            location: resultData.location,
            status: resultData.status,
            is_admin: resultData.is_admin,
          });

          //update formik field
          formikSetFieldValue("contactMethod", resultData.contact_by);
          formikSetFieldValue("location", resultData.location);

          if (resultData == "hm failed") {
          } else {
            //if success, refresh manually so list of associated profiles is updated
            //setManualRefresh(!manualRefresh);
          }
        })
        .catch((err) => {
          //if errors, assume login failed / doesn't exist
          // prep for creating a user, but perhaps don't create till entry added

          setUserObj({
            id: -10,
            secret_username: secretUsername0,
            contact_by: "",
            status: 0,
            location: "",
            is_admin: 0,
          });
          formikSetFieldValue("contactMethod", "");
          formikSetFieldValue("location", "");
        });
    }
  };

  const submitToAddOrUpdateUser = (
    secretUsername0 = "",
    contactBy0 = "",
    location0 = "[NONEGIVEN]"
  ) => {
    //add one entry at a time, as user adds it
    let theUrl = `${constants.baseURL}/nwrb/adduser`;

    //don't add user if they are trying to add to a group which does not officially exist yet:
    // that is, for now, when groupDesc == ""

    //though ideally, also wouldn't save unless they add at least one entry, could get a lot of false profiles
    // just have to manually delete later
    if (groupDesc != "") {
      // if userObj.id < 0, means should be adding new user
      // otherwise it should update existing
      fetch(theUrl, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          passkey: "ugh slow bt sorely we are getting it done",
          userId: userObj.id,
          groupId: groupId,
          secretUsername: userObj.secret_username,
          contactBy: contactBy0,
          location: location0,
          //   publicNotes: formi
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((resultData) => {
          //returns the user id, either a new one if it's a new entry, or existing if update
          // so update the state, and we can move on

          //update formik field
          //        formikSetFieldValue("contactMethod", resultData.contact_by);

          if (resultData == "hm failed") {
            //might me new user
            setUserObj({
              id: -3,
              secret_username: secretUsername0,
              contact_by: contactBy0,
              status: 0,
              location: "",
              is_admin: 0,
            });
          } else {
            //if success, refresh manually so list of associated profiles is updated
            //setManualRefresh(!manualRefresh);
            setUserObj({
              id: resultData,
              secret_username: secretUsername0,
              contact_by: contactBy0,
              status: 0,
              location: location0,
              is_admin: 0,
            });
          }
        })
        .catch((err) => {
          //          console.log("err add update user", err);
          //if errors, assume login failed / doesn't exist
          // prep for creating a user, but perhaps don't create till entry added
        });
    }
  };

  return (
    <div>
      <div className="createProfileSection container-fluid">
        <Formik
          enableReinitialize={true}
          initialValues={{
            profileName: "",
            secretUsername: "",
            contactMethod: "",
            location: "",
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(values, { setSubmitting }) => {
            //
            //similar to e.preventDefault(), do to prevent params from randomly appearing in url?
            // After handling your submission logic, you can setSubmitting(false) to enable the form again.
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <div>
              {" "}
              <br />
              {/* for reasons unknown, buttons here aren't working, hover over doesn't work, etc
            
              {infoText == true ? "true" : "false"}
              <button
                type="button"
                className="btn btn-danger pl-3"
                style={{
                  border: "2px solid black",
                  maxWidth: "80px",
                  minWidth: "70px",
                }}
                onClick={() => {
                  setInfoText(!infoText);
                }}
              >
                aa
              </button>
              <button
                style={{ height: "40px" }}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setInfoText(!infoText);
                }}
              >
                <i
                  className={
                    infoText == true
                      ? "bi bi-patch-question-fill"
                      : "bi bi-patch-question "
                  }
                ></i>
              </button> */}
              <br />
              Group Url:{" "}
              <a
                href={`https://request.azfriend.us/${groupId}`}
                target="_blank"
                rel="noreferrer"
              >
                https://request.azfriend.us/{groupId}
              </a>
              <br />
              <br />
              {infoText == true ? (
                <div>
                  In order to load your data, we need group id: '{groupId}' and
                  some secret login text from you. Note, this is like your
                  password and isn't displayed publicly! Anyone with group id
                  and your secret login text can add/edit/delete your entries,
                  so make it unique and non-guessable. The longer the better
                  (i.e. like stringing multiple words together)
                  <br />
                  <br />
                  Type in your secret login text here to load your existing
                  data, or start associating data to it (you may enter data for
                  yourself or for others, assuming you have their permission to
                  do so. All entries entered with this secret login text, are
                  loaded/managed under the same account. Note: it is case
                  sensitive, and whitespace before and after entry is not
                  recorded as part of the secret login text)
                  <br />
                  <br />
                </div>
              ) : (
                ""
              )}
              <Form>
                <b style={{}}>Key/Login Information</b>
                <br />
                Secret Login Text
                <br />
                (can be a Username/Password/Code/etc):
                <br />
                <Field
                  type="password"
                  name="secretUsername"
                  autofill="(no value entered)"
                  style={{
                    background: "orange",
                    borderLeft: "6px solid red",
                    width: "270px",
                    minHeight: "30px",
                    paddingLeft: "6px",
                    paddingTop: "5px",
                    marginLeft: "0px",
                    marginBottom: "-5px",
                  }}
                  onBlur={() => {
                    if (
                      values.secretUsername.trim() != "" &&
                      values.secretUsername.trim() != userObj.secret_username
                    ) {
                      //                        setFieldValue("secretUsername", "**hidden**");
                      //also load lists for user
                      submitToGetUserInfo(
                        values.secretUsername.trim(),
                        setFieldValue
                      );
                      setHasSecretUsername("true");
                    } else if (values.secretUsername.trim() == "") {
                      setHasSecretUsername("false");
                      setUserObj({
                        id: -1,
                        secret_username: "",
                        contact_by: "",
                        location: "",
                        is_admin: 0,
                      });
                    }
                  }}
                />
                <br />
                {hasSecretUsername == "false" ? (
                  ""
                ) : (
                  <div>
                    {infoText == true ? (
                      <div>
                        <br />
                        Enter best way to contact you if a match occurs
                        (recommended, cell phone # so can msg more easily via
                        texting). This should auto-load on a match of an
                        existing secret login text. If you edit it, it should
                        auto-save.
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    <div style={{}}>
                      Location
                      <br />
                      <Field
                        name="location"
                        as="select"
                        style={{
                          width: "270px",
                          minHeight: "30px",
                          paddingLeft: "6px",
                          paddingTop: "5px",
                          marginLeft: "0px",
                          marginBottom: "-5px",
                          textAlign: "center",
                        }}
                        onBlur={() => {
                          //if blur / lost focus, assume a change occurred and save
                          submitToAddOrUpdateUser(
                            values.secretUsername.trim(),
                            values.contactMethod.trim(),
                            values.location.trim()
                          );
                        }}
                      >
                        <option value="">{"< Select an option >"}</option>
                        <option value="Ahwatukee">Ahwatukee</option>
                        <option value="Apache Junction">Apache Junction</option>
                        <option value="Avondale">Avondale</option>
                        <option value="Casa Grande">Casa Grande</option>
                        <option value="Chandler">Chandler</option>
                        <option value="Chandler Heights">
                          Chandler Heights
                        </option>
                        <option value="East Mesa">East Mesa</option>
                        <option value="El Mirage">El Mirage</option>
                        <option value="Flagstaff">Flagstaff</option>
                        <option value="Florence">Florence</option>
                        <option value="Fountain Hills">Fountain Hills</option>
                        <option value="Gilbert">Gilbert</option>
                        <option value="Glendale">Glendale</option>
                        <option value="Goodyear">Goodyear</option>
                        <option value="Laveen Village">Laveen Village</option>
                        <option value="Litchfield Park">Litchfield Park</option>
                        <option value="Maricopa">Maricopa</option>
                        <option value="Mesa">Mesa</option>
                        <option value="North Phoenix">North Phoenix</option>
                        <option value="North Scottsdale">
                          North Scottsdale
                        </option>
                        <option value="North Tempe">North Tempe</option>
                        <option value="Paradise Valley">Paradise Valley</option>
                        <option value="Peoria">Peoria</option>
                        <option value="Phoenix">Phoenix</option>
                        <option value="Prescott">Prescott</option>
                        <option value="Queen Creek">Queen Creek</option>
                        <option value="San Tan Valley">San Tan Valley</option>
                        <option value="Scottsdale">Scottsdale</option>
                        <option value="Sedona">Sedona</option>
                        <option value="South Chandler">South Chandler</option>
                        <option value="South Tempe">South Tempe</option>
                        <option value="Sun City">Sun City</option>
                        <option value="Sun City West">Sun City West</option>
                        <option value="Surprise">Surprise</option>
                        <option value="Tolleson">Tolleson</option>
                        <option value="Tucson">Tucson</option>
                        <option value="AZ but city not listed">
                          AZ but city not listed
                        </option>
                        <option value="state not AZ">
                          Other State (not AZ)
                        </option>
                      </Field>
                    </div>
                    <br />

                    <div>
                      Way to contact you (i.e. cell phone #, email):
                      <br />
                      <Field
                        type="text"
                        name="contactMethod"
                        placeholder="(should've autofilled if existing user)"
                        style={{
                          width: "270px",
                          minHeight: "30px",
                          paddingLeft: "6px",
                          paddingTop: "5px",
                          marginLeft: "0px",
                          marginBottom: "-5px",
                          textAlign: "center",
                        }}
                        onBlur={() => {
                          if (
                            values.contactMethod.trim() !=
                              userObj.contact_by.trim() &&
                            values.contactMethod.trim() != ""
                          ) {
                            //if contact method changed, send query to add/update user
                            //otherwise if this doesn't change, assume just loading data
                            // (since this is required for new users. And old users will likely keep the same)
                            submitToAddOrUpdateUser(
                              values.secretUsername.trim(),
                              values.contactMethod.trim(),
                              values.location.trim()
                            );
                          } else if (
                            values.contactMethod.trim() ==
                              userObj.contact_by.trim() ||
                            values.contactMethod.trim() == ""
                          ) {
                            //don't let them unset it to "" if it existed prior
                            setUserObj({
                              id: userObj.id,
                              secret_username: userObj.secret_username,
                              contact_by: userObj.contact_by,
                              location: userObj.location,
                              is_admin: userObj.is_admin,
                            });
                            setFieldValue("contactMethod", userObj.contact_by);
                            setFieldValue("location", userObj.location);
                          }
                        }}
                      />
                    </div>

                    <br />
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </div>

      <div style={{ borderBottom: "5px solid blue", marginTop: "16px" }} />
      <div style={{ padding: "0px 0px" }}>
        <div id="tabfmhcontents">
          <ul className="nav nav-tabs" id="myTab3" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link"
                id="fhmex-tab"
                data-toggle="tab"
                href="#fhmex"
                role="tab"
                aria-controls="fhmexac"
                aria-selected="false"
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                id="fhmcreate-tab"
                data-toggle="tab"
                href="#fhmcreate"
                role="tab"
                aria-controls="fhmcreateac"
                aria-selected="true"
              >
                Request/Offer Board
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="fhmsearch-tab"
                data-toggle="tab"
                href="#fhmsearch"
                role="tab"
                aria-controls="fhmsearchac"
                aria-selected="false"
              >
                Add/Edit Your Items
              </a>
            </li>
          </ul>

          <div className="tab-content" id="TabContent">
            <div
              className="tab-pane fade ml-3"
              id="fhmex"
              role="tabpanel"
              style={{ textAlign: "left" }}
              aria-labelledby="fhmex-tab"
            >
              <InfoPage />
            </div>

            <div
              className="tab-pane fade show active "
              id="fhmcreate"
              role="tabpanel"
              style={{ textAlign: "left" }}
              aria-labelledby="fhmcreate-tab"
            >
              <ListedItemsPage
                theUser={userObj}
                groupDetails={groupDesc}
                manualRefresh={manualRefresh}
              />
            </div>

            <div
              className="tab-pane fade "
              id="fhmsearch"
              role="tabpanel"
              style={{ textAlign: "left" }}
              aria-labelledby="fhmsearch-tab"
            >
              <FriendMatchCreateMobile
                theUser={userObj}
                groupDesc={groupDesc}
                cbSetGroupDesc={cbSetGroupDesc}
                cbToggleToManualRefresh={cbToggleToManualRefresh}
              />
            </div>
          </div>
        </div>
        <br />
      </div>
      <br />
    </div>
  );
};

export default NWRHub;
