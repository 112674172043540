import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import { AppContext, UserContext } from "./components/Contexts";

import NWRHub from "./pages/NWRHub";

function App() {
  //state

  const [manualRefresh, setManualRefresh] = useState(false);
  const toggleManualRefresh = () => {
    setManualRefresh(!manualRefresh);
  };

  const [dispWidth, setDispWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false); //767px and <
  useEffect(() => {
    // Check if the initial window width indicates a mobile device
    setIsMobile(window.innerWidth < 768);
    setDispWidth(window.innerWidth);

    // Listen for window resize events
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setDispWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [groupDesc, SetGroupDesc] = useState("");
  //common context items ----------

  const [loggedInUser, setLoggedInUser] = useState({});

  return (
    <div className="App" style={{ background: "lightgray" }}>
      <header
        className="py-4"
        style={{
          background: "whitesmoke",
          height:
            groupDesc == "Hand of Mercy"
              ? dispWidth < 701
                ? "190px"
                : "170px"
              : "130px",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4>Needs/Wants/Requests Board</h4>
              {groupDesc == "" ? "" : `for ${groupDesc}`}
              {groupDesc == "Hand of Mercy" ? (
                <div style={{}}>
                  (Note, a quirk of this site, is that it does not work on wifi
                  from Pastor Pete's house.
                  {dispWidth > 700 ? <br /> : ""}
                  To access from there, either need to use a VPN, or turn off
                  wifi to use cell phone's signal)
                </div>
              ) : (
                ""
              )}
              <div className="flex justify-center" style={{ marginTop: "5px" }}>
                <img
                  src={logo}
                  className="App-logo"
                  alt="logo"
                  width="50"
                  style={{
                    padding: "0px",
                    margin: "-5px",
                    marginLeft: "-8px",
                    height: "100px",
                    marginTop: "-35px",
                  }}
                />
                version 0.240422{isMobile ? "_m" : ""}
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="Body">
        <Router>
          <AppContext.Provider value={{ theme: "light", dispWidth: dispWidth }}>
            <UserContext.Provider value={loggedInUser}>
              <Route
                path="/:groupId"
                render={(props) => (
                  <NWRHub
                    {...props}
                    isMobile={isMobile}
                    groupDesc={groupDesc}
                    cbSetGroupDesc={SetGroupDesc}
                    manualRefresh={manualRefresh}
                    cbToggleToManualRefresh={toggleManualRefresh}
                  />
                )}
              />
            </UserContext.Provider>
          </AppContext.Provider>
        </Router>
      </div>

      <div className="Footer" style={{ background: "gray" }}>
        <hr />
        <div className="mx-3">©2023-2024 DeltaPeng</div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default App;
