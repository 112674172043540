import React, { useState } from "react";

//toggles back and forth to manually refresh topic lists in MyProfile when altered in EditProfile
/*
const [manualRefreshTopics, SetManualRefreshTopics] = useState(false);
const GoRefreshTopics = () => {
    //altering this value should kick off the refresh useEffect
    SetManualRefreshTopics(!manualRefreshTopics)
}
*/

//contexts with their default values in, gives the shape of what it should look like.
// Later, at top level in App, will also need to provide/pass_in values for the context 'providers',
// which are later used/consumed by context 'consumers'
const AppContext = React.createContext({
  //version: "230217",
  //author: "deltapeng",
  dispWidth: 100,
  theme: "dark",
  DoManualRefresh: () => {},
});

const ThemeContext = React.createContext({
  theme: "dark",
});

const UserContext = React.createContext({
  login_id: -1,
  username: "guest",
  sex: "U",
  age: "120",
  relationship: "U",
  email: "",
  pics: [],
  banned: "",
  banned_reason: "",
});

export { AppContext, ThemeContext, UserContext };
