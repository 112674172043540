import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Alert from "../components/radix-ui/deltapeng/Alert";

import constants from "../components/constants";
import { SendNotifToQueue } from "../components/commonFunctions";

const ListedItemsPage: React.FC<any> = (props) => {
  // where stateTopic consists of all your topic data, and ~should be ordered by stars already

  const {
    theUser = { id: -1 },
    groupDetails = "",
    manualRefresh = false,
  } = props;

  /////////////////

  //get router params
  let { groupId }: any = useParams();

  const [stateWants, setStateWants] = useState([] as any[]);
  const [stateOffers, setStateOffers] = useState([] as any[]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  //need useEffect to transfer the result data into an array
  useEffect(() => {
    //	console.log("check inputs prior", userId, topicName)

    if (groupId != null) {
      // originally filtered the user's items out of the list
      // but, maybe better to just get it all, and filter manually here on frontend
      // there may/may not be a username entered just yet, if filter here, better to have both states
      fetch(`${constants.baseURL}/nwrb/getlist`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          passkey: "fair enough I suupose if no pw then we need a new one",
          groupId: groupId,
          // secretUsername: "",
        }),
      })
        .then((response) => response.json())
        .then((topicsRaw) => {
          let theWants: any[] = [];
          let theOffers: any[] = [];

          topicsRaw.forEach((topicr: any) => {
            if (topicr.iswantoroffer == 0) {
              theWants.push(topicr);
            } else if (topicr.iswantoroffer == 1) {
              theOffers.push(topicr);
            }
          });

          //          console.log("set into state", theWants, theOffers);
          setStateWants(theWants);
          setStateOffers(theOffers);

          setIsLoaded(true);
        })
        .catch((err) => {});
    }

    //need to add userId to below, cause it starts as undefined, before the ptops comee in and give it a value.
    // set it here so it is watching for changes to this value
  }, [groupId, manualRefresh]);

  const submitToLogBellRequest = (
    userIdOwner0 = -1,
    needId0 = -1,
    userIdRequester0 = -1,
    response = ""
  ) => {
    //add one entry at a time, as user adds it

    //submit to reg fz db ---------------
    let theUrl = `${constants.baseURL}/nwrb/addbell`;

    fetch(theUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey: "this is a good idea I like good ideas git gud",
        userIdOwner: userIdOwner0,
        needId: needId0,
        userIdRequester: userIdRequester0,
        response: response,
      }),
    })
      .then((response) => response.json())
      .then((resultData) => {
        //        console.log("bell done", resultData);
        //if success, refresh manually so list of associated profiles is updated
        //setManualRefresh(!manualRefresh);
      })
      .catch((err) => {});
    // end submit to reg fz db ---------------

    //also submit to flexdb ---------------
    let newContent = {
      need_id: needId0,
      content: response, //change main content field to be "content"
      user_id_owner: userIdOwner0,
      internal_notes: "",
      user_id_requester: userIdRequester0,
    } as any;

    fetch("https://flexdbapi.azfriend.us/flex", {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey: "gr9jaiFA0Tnever ervr everwk20jgwGSDG lweG E3",
        dataName: "nwrb_bells",
        dataAccessKey: "duplicateFdb for time being",
        rowId: 0, //to create new id for it
        data: JSON.stringify(newContent),
      }),
    })
      .then((response) => response.json())

      .then((resp) => {
        console.log("success? flexdb");
      })

      .catch((err) => {
        //console.log(err)
      });
    //end flex db ---------------
  };

  function ReplaceAll(inputStr: string, find: string, replace: string) {
    if (inputStr === "") return inputStr;
    if (find === "") return inputStr;
    if (find === replace) return inputStr;

    //updated to arr to ensure we hit all instances, and no issues with replace values containing a char from find
    let strArr = inputStr.split(find);
    let strOutput = "";
    strArr.forEach((e, indx: number) => {
      strOutput += strArr[indx] + replace;
    });

    /*
        //has a flaw, if replacing say " with "" it will go indefinitely
        while (inputStr.indexOf(find) > -1) {
            inputStr = inputStr.replace(find, replace);
        }
    */

    return strOutput.substring(0, strOutput.length - replace.length);
  }

  //would be nice, but doesn't work as is
  // const submitToEmailAdminOfBellRequest = (theMessage: string) => {
  //   fetch(`${constants.baseURL}/toast/send`, {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       passkey: "49062d22-463e-mma3-bn4bf86d-9dd9-4159-snai-ldc7c2699269",
  //       emails: "tngai13@gmail.com",
  //       title: "NWRB notif request",
  //       message: theMessage,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     //according to the API we made, the data returned will be the user this time, so for clarification change var data below to user
  //     .then((resp) => {
  //       //        console.log("test email resp", resp);
  //     })
  //     .catch((err) => {
  //       //      console.log("test email err", err);
  //       //or 1234567890@tmomail.net
  //     });
  // };

  //compared to current date
  function get_timediff(datetimeInput: Date) {
    //var datetime2 = typeof datetime !== 'undefined' ? datetime : new Date();

    var datetime2 = new Date(datetimeInput).getTime();
    var now = new Date().getTime();

    if (isNaN(datetime2)) {
      return "";
    }

    //console.log( datetime2 + " " + now);
    var milisec_diff = 0;
    if (datetime2 < now) {
      milisec_diff = now - datetime2;
    } else {
      milisec_diff = datetime2 - now;
    }

    var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24));

    //var date_diff = new Date( milisec_diff );

    // return days + " Days "+ date_diff.getHours() + " Hours " + date_diff.getMinutes() + " Minutes " + date_diff.getSeconds() + " Seconds";
    if (days === 0) return "Today";
    else return days + " Days ago";
  }

  //get freshness of a recent update
  let dateTodayMinus10 = moment();
  let dateTodayMinus1Month = moment(dateTodayMinus10).subtract(1, "months");
  let dateTodayMinus3Months = moment(dateTodayMinus10).subtract(3, "months");
  dateTodayMinus10 = moment(dateTodayMinus10).subtract(1, "days");

  function doesHaveBadge(lastUpdatedTimestamp: moment.Moment) {
    //check to see if this item has a badge (aka there was
    // recent activity, within past 7 days. If so, then can do something, like highlight the user in some fashion)
    if (lastUpdatedTimestamp.isBefore(dateTodayMinus10)) {
      return false;
    } else {
      return true;
    }
  }
  function howRecentlyUpdated(lastUpdatedTimestamp: moment.Moment) {
    //we'll make the limits different on this social site, as there's less reason to visit
    // fresh < past week, past month, past 3 months, and none for over that
    if (lastUpdatedTimestamp.isBefore(dateTodayMinus3Months)) {
      return "";
      // (
      //   // 231224 until it becomes more popular, cancel this out if not admin

      //   <span className="badge badge-secondary" style={{ marginRight: "10px" }}>
      //     Older Requests
      //   </span>
      // );
    } else if (lastUpdatedTimestamp.isBefore(dateTodayMinus1Month)) {
      return "";
    } else if (lastUpdatedTimestamp.isBefore(dateTodayMinus10)) {
      return "";
      // (
      //   <span className="badge badge-primary" style={{ marginRight: "10px" }}>
      //     Within month
      //   </span>
      // );
    } else {
      return (
        //within 1 day
        <span className="badge badge-success" style={{ marginRight: "10px" }}>
          Fresh!
        </span>
      );
    }
  }

  //don't display site if data is not loaded yet
  if (!isLoaded) {
    return (
      <div className="mx-3" style={{ marginTop: "30px" }}>
        <div className="my-1 progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-info"
            role="progressbar"
            style={{ width: "30%" }}
          ></div>
        </div>
        <p>Loading wants/offers list...</p>
      </div>
    );
  }

  return (
    <div>
      <strong>
        <u className="ml-2">{groupDetails} Group</u>
      </strong>
      <b>{statusMsg !== "" ? statusMsg : ""}</b>
      <br />
      {theUser.id < 1 ? "** You are not logged in **" : ""}
      <div className="mt-3 container-fluid mr-2">
        <h4
          style={{
            borderLeft: "6px solid orange",
            paddingLeft: "4px",
          }}
        >
          Wants ({stateWants.length})
        </h4>
        {stateWants.map((item: any) => {
          return (
            <div key={`${item.id}_${item.associated_name}`}>
              <div className="row">
                <div
                  style={{
                    border: "2px solid black",
                    marginBottom: "-1px",
                    padding: "4px",
                    borderLeft:
                      item.user_id == theUser.id
                        ? "4px solid red"
                        : "2px solid black",
                  }}
                >
                  <strong>
                    {theUser.is_admin || theUser.id == item.user_id
                      ? "[" + item.associated_name + "] "
                      : ""}
                  </strong>
                  {item.name}{" "}
                  {item.expertise_level == ""
                    ? ""
                    : "(" + item.expertise_level + ")"}{" "}
                  {item.activity_notes != "" ? (
                    item.isnoteapproved ? (
                      <div style={{ background: "white", padding: "0px 4px" }}>
                        {item.activity_notes}
                      </div>
                    ) : (
                      <div style={{ background: "white", padding: "0px 4px" }}>
                        ** details pending review **
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  {howRecentlyUpdated(moment(item.date_created))}
                  {theUser.is_admin
                    ? "[ " + get_timediff(item.date_created) + " ] "
                    : ""}
                  {theUser.id < 1 ? (
                    ""
                  ) : item.user_id == theUser.id ? (
                    ""
                  ) : (
                    <Alert
                      buttonType="btn btn-warning"
                      confirmColor="orange"
                      buttonStyle={{
                        margin: "0px",
                        padding: "6px 12px",
                        width: "22px",
                        height: "24px",
                      }}
                      handleClickConfirmed={() => {
                        let tempResp = `user(${theUser.id}) from ${
                          theUser.location
                        }(${
                          theUser.contact_by
                        }) is open to helping the Want of (${item.user_id})[${
                          item.associated_name
                        }]'s ${item.name} ${
                          item.expertise_level == ""
                            ? ""
                            : "(" + item.expertise_level + ")"
                        }${
                          item.activity_notes != ""
                            ? item.isnoteapproved
                              ? " <" + item.activity_notes + ">"
                              : " <** note pending review **>"
                            : ""
                        }`;

                        //new code, send notif flexdb to appropriate queue
                        let newContentObj = {
                          app: "nwrb",
                          app_trigger: "trigger offer request",
                          from_user: theUser.id,
                          from_location: theUser.location,
                          itemCategory: "offer/service",
                          to_user: item.user_id,
                          to_location: "",
                          itemId: item.id,
                          content: tempResp,
                          date_resolved: "",
                          actions: [
                            "evaluate and decide if should contact the two",
                            "flag requestee if inappropriate",
                            "log request to backup",
                          ],
                        };

                        setStatusMsg("Processing... ...");

                        SendNotifToQueue(newContentObj).then((respSuccess) => {
                          //                  console.log("resp check", respSuccess);
                          if (respSuccess > 0) {
                            setStatusMsg(
                              "<< Bell notification submitted on _ " +
                                moment(Date.now()).format("HH:mm") +
                                " >>"
                            );
                          } else {
                            setStatusMsg(
                              "Bell notification failed at: " +
                                moment(Date.now()).format("HH:mm") +
                                `
                    
                    Please try again.`
                            );
                          }
                        });

                        // submitToLogBellRequest(
                        //   item.user_id,
                        //   item.id,
                        //   theUser.id,
                        //   tempResp
                        // );

                        //submitToEmailAdminOfBellRequest(tempResp);
                      }}
                      confirmationMessage={`...that you are open to potentially helping fulfill the other person's Want of '${
                        item.name
                      } ${
                        item.expertise_level == ""
                          ? ""
                          : "(" + item.expertise_level + ")"
                      }'${
                        item.activity_notes != ""
                          ? item.isnoteapproved
                            ? " <" + item.activity_notes + ">"
                            : " <** note pending review **>"
                          : ""
                      }, and you can be contacted via <${theUser.contact_by}>`}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <div className="mt-3 container-fluid mr-2">
        <h4
          style={{
            borderLeft: "6px solid blue",
            paddingLeft: "4px",
          }}
        >
          {" "}
          Offers ({stateOffers.length})
        </h4>
        {stateOffers.map((item: any) => {
          return (
            <div key={`${item.id}_${item.associated_name}`}>
              <div className="row">
                <div
                  style={{
                    border: "2px solid black",
                    marginBottom: "-1px",
                    padding: "4px",
                    borderLeft:
                      item.user_id == theUser.id
                        ? "4px solid red"
                        : "2px solid black",
                  }}
                >
                  <strong>
                    {theUser.is_admin || theUser.id == item.user_id
                      ? "[" + item.associated_name + "] "
                      : ""}
                  </strong>
                  {item.name}{" "}
                  {item.expertise_level == ""
                    ? ""
                    : "(" + item.expertise_level + ")"}{" "}
                  {item.activity_notes != "" ? (
                    item.isnoteapproved ? (
                      <div style={{ background: "white", padding: "0px 4px" }}>
                        {item.activity_notes}
                      </div>
                    ) : (
                      <div style={{ background: "white", padding: "0px 4px" }}>
                        ** details pending review **
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  {howRecentlyUpdated(moment(item.date_created))}
                  {theUser.is_admin
                    ? "[ " + get_timediff(item.date_created) + " ] "
                    : ""}
                  {theUser.id < 1 ? (
                    ""
                  ) : item.user_id == theUser.id ? (
                    ""
                  ) : (
                    <Alert
                      buttonType="btn btn-primary"
                      confirmColor="blue"
                      buttonStyle={{
                        margin: "0px",
                        padding: "6px 12px",
                        width: "22px",
                        height: "24px",
                      }}
                      handleClickConfirmed={() => {
                        let tempResp = `user(${theUser.id}) from ${
                          theUser.location
                        }(${theUser.contact_by}) requests the Offer of (${
                          item.user_id
                        })[${item.associated_name}]'s ${item.name} ${
                          item.expertise_level == ""
                            ? ""
                            : "(" + item.expertise_level + ")"
                        }${
                          item.activity_notes != ""
                            ? item.isnoteapproved
                              ? " [" + item.activity_notes + "]"
                              : " [** note pending review **]"
                            : ""
                        }`;

                        // submitToLogBellRequest(
                        //   item.user_id,
                        //   item.id,
                        //   theUser.id,
                        //   tempResp
                        // );

                        //submitToEmailAdminOfBellRequest(tempResp);

                        //new code, send notif flexdb to appropriate queue
                        let newContentObj = {
                          app: "nwrb",
                          app_trigger: "trigger want request",
                          from_user: theUser.id,
                          from_location: theUser.location,
                          itemCategory: "need/want/request",
                          to_user: item.user_id,
                          to_location: "",
                          itemId: item.id,
                          content: tempResp,
                          date_resolved: "",
                          actions: [
                            "evaluate and decide if should contact the two",
                            "flag requestee if inappropriate",
                            "log request to backup",
                          ],
                        };

                        setStatusMsg("Processing... ...");

                        SendNotifToQueue(newContentObj).then((respSuccess) => {
                          //                  console.log("resp check", respSuccess);
                          if (respSuccess > 0) {
                            setStatusMsg(
                              "<< Bell notification submitted on _ " +
                                moment(Date.now()).format("HH:mm") +
                                " >>"
                            );
                          } else {
                            setStatusMsg(
                              "Bell notification failed at: " +
                                moment(Date.now()).format("HH:mm") +
                                `
                    
                    Please try again.`
                            );
                          }
                        });
                      }}
                      confirmationMessage={`...that you would like the other person's potential Offer of '${
                        item.name
                      } ${
                        item.expertise_level == ""
                          ? ""
                          : "(" + item.expertise_level + ")"
                      }'${
                        item.activity_notes != ""
                          ? item.isnoteapproved
                            ? " [" + item.activity_notes + "]"
                            : " [** note pending review **]"
                          : ""
                      }, and you can be contacted via <${theUser.contact_by}>`}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListedItemsPage;
