import React from "react";
import * as RadixAlertDialog from "@radix-ui/react-alert-dialog";
import { styled, keyframes } from "@stitches/react";
import {
  violet,
  blackA,
  red,
  green,
  orange,
  blue,
  mauve,
} from "@radix-ui/colors";

export const Alert = (props) => {
  const {
    buttonType = "btn btn-light",
    confirmColor = "green",
    buttonStyle = {},
    handleClickConfirmed = null,
    confirmationMessage = "default message",
  } = props;

  return (
    <RadixAlertDialog.Root>
      <RadixAlertDialog.Trigger asChild>
        <Button className={buttonType} style={buttonStyle}>
          {" "}
          <i className="bi bi-bell"></i>
        </Button>
      </RadixAlertDialog.Trigger>
      <RadixAlertDialog.Portal>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogTitle>
            By sending admin this notification for review, you are saying:
          </AlertDialogTitle>
          <AlertDialogDescription>{confirmationMessage}</AlertDialogDescription>
          <Flex css={{ justifyContent: "flex-end" }}>
            <RadixAlertDialog.Cancel asChild>
              <Button variant="mauve" css={{ marginRight: 25 }}>
                Cancel
              </Button>
            </RadixAlertDialog.Cancel>
            <RadixAlertDialog.Action asChild>
              <Button variant={confirmColor} onClick={handleClickConfirmed}>
                Yes, send the notification
              </Button>
            </RadixAlertDialog.Action>
          </Flex>
        </AlertDialogContent>
      </RadixAlertDialog.Portal>
    </RadixAlertDialog.Root>
  );
};

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const AlertDialogOverlay = styled(RadixAlertDialog.Overlay, {
  backgroundColor: blackA.blackA9,
  position: "fixed",
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const AlertDialogContent = styled(RadixAlertDialog.Content, {
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "500px",
  maxHeight: "85vh",
  padding: 25,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  "&:focus": { outline: "none" },
});

const AlertDialogTitle = styled(RadixAlertDialog.Title, {
  margin: 0,
  color: mauve.mauve12,
  fontSize: 17,
  fontWeight: 500,
});

const AlertDialogDescription = styled(RadixAlertDialog.Description, {
  marginBottom: 20,
  color: mauve.mauve11,
  fontSize: 15,
  lineHeight: 1.5,
});

const Flex = styled("div", { display: "flex" });

const Button = styled("button", {
  all: "unset",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  padding: "0 15px",
  fontSize: 15,
  lineHeight: 1,
  fontWeight: 500,
  height: 35,

  variants: {
    variant: {
      orange: {
        backgroundColor: orange.orange4,
        color: orange.orange11,
        "&:hover": { backgroundColor: orange.orange5 },
        "&:focus": { boxShadow: `0 0 0 2px ${orange.orange7}` },
      },
      blue: {
        backgroundColor: blue.blue4,
        color: blue.blue11,
        "&:hover": { backgroundColor: blue.blue5 },
        "&:focus": { boxShadow: `0 0 0 2px ${blue.blue7}` },
      },
      violet: {
        backgroundColor: "white",
        color: violet.violet11,
        boxShadow: `0 2px 10px ${blackA.blackA7}`,
        "&:hover": { backgroundColor: mauve.mauve3 },
        "&:focus": { boxShadow: `0 0 0 2px black` },
      },

      green: {
        backgroundColor: green.green4,
        color: green.green11,
        "&:hover": { backgroundColor: green.green5 },
        "&:focus": { boxShadow: `0 0 0 2px ${green.green7}` },
      },
      red: {
        backgroundColor: red.red4,
        color: red.red11,
        "&:hover": { backgroundColor: red.red5 },
        "&:focus": { boxShadow: `0 0 0 2px ${red.red7}` },
      },
      mauve: {
        backgroundColor: mauve.mauve4,
        color: mauve.mauve11,
        "&:hover": { backgroundColor: mauve.mauve5 },
        "&:focus": { boxShadow: `0 0 0 2px ${mauve.mauve7}` },
      },
    },
  },

  defaultVariants: {
    variant: "violet",
  },
});

export default Alert;
