import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import constants from "../components/constants";

//import { AppContext, UserContext } from "../components/Contexts";
import { ReplaceAll } from "../components/commonFunctions";

const InfoPage: React.FC<any> = (props) => {
  // where stateTopic consists of all your topic data, and ~should be ordered by stars already

  return (
    <div>
      <pre
        className="row col-12"
        style={{
          resize: "none",
          wordWrap: "break-word", //overflows but keeps indiv words intact
          whiteSpace: "pre-wrap", //this seems to be the ket attrib to being able to resize, combined with pre to preserve line breaks

          maxWidth: "500px",
        }}
      >
        {`230821
If any issues or questions, feel free to text me, 480-516-2582

Purpose: 
The intention is to provide a tool to help strengthen the community, and bring people closer together. This is done by helping voice, display, then network/match up the various needs/wants/requests/offers within the community.

Problem Statement / Potential hurdles:
-We all want strong friendships and connections, but don't know how to get it
-People are busy, and everyone has different schedules
-The older you get, the busier you tend to get (limited time)
-Everyone is looking for different things
-It's hard to find like-minded and like-interested people
-People don't always know what they want, nor how to communicate that
-Not everyone is tech saavy, or don't wish to manage too many different accounts

Proposed Solution: 
We need some means to safely communicate what we want/need, and find others who have similar wants/needs/interests/hobbies

Some people might have more experience, knowledge in the areas we seek, some may even be willing to offer those things as services already. But we don't know that offhand, somehow the people need to communicate the request or the willingness to help

These people exist, but we need a way to find and match up with them

The only way to do this is to collect better and honest/true info from those with interest. If you can't communicate what it is you want or are looking for, it will be harder to find it and less likely you'll get it. The converse is true, the better you are at communicating what you need/want, the more likely it is you may get it

Hence, we need a central place and an easy means to be able to write, gather, collect this info. If we can get a list of needs and a list of wants, we may begin to see overlap.

Safety is a factor when dealing with people, especially strangers. It needs to be accessible, and not too hard to view/access. We need a way to root out scammers, spammers, bad actors, suspicious activity.

A website makes it readily available to all. A group ID prevents random strangers from getting in. A password to allow you a way to add/edit/delete entries. A means to contact a match (in this case, this isn't implemented directly. Press the bell to notify an admin for assistance related to a need/offer, and admin will chat with and help connect the people, to decide where to go/ what to do from there). Added a way to add entries for others, so can just have one person managing for multiple (less tech saavy or etc) people as desired.

How to use site:
Go to tab "Request/Offer Board" to see the requests within the community so far (if logged in by entering some secret login text (essentially, like a password to manage your account), it will mark your own requests with a red highlight on the left side, as well as displaying the associated name of your requests).
If you are logged in and see a request you have interest in, press the bell, then the button to confirm you want to send admin a message. Admin will review, then contact you as needed via your "Way to contact you"

To add your own requests/offers for yourself or others: 
-go to tab "Add/Edit Your Items" entries. 
-Take note of the url and group id (can bookmark it to make it easier to come back to)
-create and add a secret login text (or use an existing one to "log" in), add a means to contact you
-Create new entries, one for each activity you have interest in. 
-Add the associated name, that is, the name of the person the activity is related to (you, a friend/fam member)
-add activity
-add level of expertise/interest/passion you have or are seeking, Lv0 being a beginner, Lv100 being an expert. I.e. if you want a master piano player, you would add the activity, set it to a want, and set the expertise level to a higher level
-Can add items for you, or for friends (if you have their permission to do so).

Disclaimer:
Note this is a personal site, provided as is. I reserve the right to adjust/delete/record data as needed, block access, etc. It's intended for adults aged 18+ to use. 

Be smart and safe with anything involving the internet and potentially meeting people you don't know yet. Safer methods include: meeting in public, bringing a friend with you, making sure you have a working video chat online with the person first to get a feel for whether they are trustworthy, etc. 

Don't give out personal contact info or personally identifying info to those you are uncertain about. You are responsible for any and all data you input, and any resulting meetings that happen. If in doubt, take the safer more cautious route, and ask trusted friends and family of their opinion on it prior. This site is just a tool, a means to try to help like-minded/interested people find each other, to help ppl network and hopefully build stronger communities and friendship. There's no guaranteed what kind of quality of ppl use this site. Take precautions and be safe! 

Feel free to let me know what you think about the site and any ppl met through it, and I can take notes to try to improve the overall quality of it over time for all.
`}
      </pre>
    </div>
  );
};

export default InfoPage;
