import React, { useState, useContext, useEffect } from "react";
import { AppContext, UserContext } from "../components/Contexts";

import { useParams } from "react-router-dom";

//import PicUpload from "../components/PicUpload";

//bootstrap checkboxes
//import Form from "react-bootstrap/Form";

import { Piece } from "../components/commonFunctions";

import constants from "../components/constants";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const FriendMatchCreateMobile: React.FC<any> = (props) => {
  const {
    theUser = { id: -1, secret_username: "", contact_by: "" },
    groupDesc = "",
    cbSetGroupDesc = null,
    cbToggleToManualRefresh = null,
  } = props;

  const theApp = useContext(AppContext);
  //  const theUser = useContext(UserContext);

  let { groupId }: any = useParams();

  function flattenArrVals(inputArr: any) {
    //flatten all values of array, so we can check whether a value exists in it easily
    if (inputArr == undefined || inputArr == null) return "";

    let outputStr = "|";

    inputArr.forEach((item: any) => {
      outputStr += item + "|";
    });
    // i.e. if ["i1","abc"] -> ,"i1","abc", so can check includes ,"itemname",
    return outputStr;
  }

  // list of selected items in lists, moreso for display purposes
  const [interestArrOffers, setInterestArrOffers] = useState([] as string[]);
  const [interestArrWants, setInterestArrWants] = useState([] as string[]);
  // create a list of objects also, includes more data, for saving
  const [interestArrObjOffers, setInterestArrObjOffers] = useState([] as any[]);
  const [interestArrObjWants, setInterestArrObjWants] = useState([] as any[]);

  const [selectedInterest, setSelectedInterest] = useState("");
  const [selectedWantOffer, setSelectedWantOffer] = useState("want");

  const [selectedExpertise, setSelectedExpertise] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  //  let defaultStarRating = 10;

  const [status, setStatus] = useState("");

  //should this be ~3, 5, and up to 10 if good rep?
  const NUM_INTEREST_LIMIT = 20;

  const [manualRefresh, setManualRefresh] = useState(false);
  const [interestsArr, setInterestsArr] = useState([] as any[]);

  //load initial group info + activities list
  useEffect(() => {
    if (groupId.trim() != "") {
      fetch(`${constants.baseURL}/nwrb/getgroup`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          passkey: "the good form of isolation letz keep things organized",
          groupId: groupId.trim(),
        }),
      })
        .then((response) => response.json())
        .then((topicsRaw) => {
          let interestsArr0 = [] as any[];
          //for each item in topicsRaw array, if it's activity log, set to temp string variable
          topicsRaw.forEach((item: any) => {
            if (item.type == "group") {
              // instead of own state, sicne we're loading group data here, send it back to parent to store in parent state
              // from there, parent can distribute to a different child
              // not ideal (compared to fetching from parent), but interesting that it works
              // plus this gets interests arr for dropdown, which we want here, not really in parent.
              //              SetGroupDesc(item.name);
              cbSetGroupDesc(item.name);
            }

            if (item.type == "activity") {
              interestsArr0.push(item);
            }
          });
          /*
            if (item.type == "activity") {
              strActivity +=
                moment(item.date_created).format("MMM/D/YY, ddd H:mm") +
                "_" +
                item.content +
                "\n";
            }
            */

          setInterestsArr(interestsArr0);
          //sort and store data in separate arrays
          setIsLoaded(true);
        })
        .catch((err) => {
          //btnSubmit0.disabled = false;
        });
    }
  }, [manualRefresh, groupId]); //should it matter in app settings, if user changes? Maybe if user settings/prefs are loaded with it

  //load initial group info + activities list
  useEffect(() => {
    //when userid changes, try loading data
    submitToGetUserLists(theUser.secret_username);
  }, [manualRefresh, theUser.id]); //should it matter in app settings, if user changes? Maybe if user settings/prefs are loaded with it

  const submitToGetUserLists = (secretUsername0 = "") => {
    //add one entry at a time, as user adds it
    let theUrl = `${constants.baseURL}/nwrb/get`;

    if (secretUsername0 && secretUsername0.trim() != "") {
      fetch(theUrl, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          passkey: "welcomm back if urr an existing user",
          groupId: groupId,
          secretUsername: secretUsername0,
        }),
      })
        .then((response) => response.json())
        .then((resultData) => {
          let tempArrWant = [] as any[];
          let tempArrObjWant = [] as any[];
          let tempArrOffer = [] as any[];
          let tempArrObjOffer = [] as any[];
          resultData.forEach((item: any) => {
            if (item.activity_id > 0) {
              if (item.iswantoroffer == 0) {
                let tempStr: string =
                  "[" +
                  item.associated_name.trim() +
                  "] " +
                  item.name +
                  (item.expertise_level == ""
                    ? ""
                    : " (" + item.expertise_level + ")") +
                  (item.activity_notes.trim() != ""
                    ? " <" + item.activity_notes + ">"
                    : "");

                tempArrWant.push(tempStr);

                tempArrObjWant.push({
                  associatedName: item.associated_name.trim(),
                  isWantOrOffer: 0,
                  activityId: item.activity_id,
                  activityName: item.name,
                  activityNotes: item.activity_notes,
                  expertiseLevel: item.expertise_level,
                });
              } else if (item.iswantoroffer == 1) {
                let tempStr: string =
                  "[" +
                  item.associated_name.trim() +
                  "] " +
                  item.name +
                  (item.expertise_level == ""
                    ? ""
                    : " (" + item.expertise_level + ")") +
                  (item.activity_notes.trim() != ""
                    ? " <" + item.activity_notes + ">"
                    : "");

                tempArrOffer.push(tempStr);

                tempArrObjOffer.push({
                  associatedName: item.associated_name.trim(),
                  isWantOrOffer: 1,
                  activityId: item.activity_id,
                  activityName: item.name,
                  activityNotes: item.activity_notes,
                  expertiseLevel: item.expertise_level,
                });
              }
            }
          });

          setInterestArrOffers(tempArrOffer);
          setInterestArrObjOffers(tempArrObjOffer);
          setInterestArrWants(tempArrWant);
          setInterestArrObjWants(tempArrObjWant);

          if (resultData == "hm failed") {
            setStatus(
              "profile save failed, probably due to duplicate profile name already existing. Errored on " +
                moment(new Date()).format("HH:mm")
            );
          } else {
            setStatus(
              "profile should be saved, on " +
                moment(new Date()).format("HH:mm")
            );

            //if success, refresh manually so list of associated profiles is updated
            //setManualRefresh(!manualRefresh);
          }
        })
        .catch((err) => {
          //console.log(err)
          //btnSubmit0.disabled = false;
          //if login failed
        });
    }
    //theApp.DoManualRefreshUserPics();
  };

  const submitToAddRequestForUser = (
    formikValues: any,
    userId0 = -1,
    isWantOrOffer0 = 0
  ) => {
    //add one entry at a time, as user adds it
    let theUrl = `${constants.baseURL}/nwrb/adduserreq`;

    fetch(theUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey:
          "help yourself to anything in the kitchenthere be hungry mouths to feed",
        userId: userId0,
        associatedName: formikValues.associatedName.trim(),
        isWantOrOffer: isWantOrOffer0,
        activityId: Piece(selectedInterest, "|", 1, 1),
        expertiseLv: selectedExpertise,
        activityNotes: formikValues.activityNotes.trim(),
      }),
    })
      .then((response) => response.json())
      .then((resultData) => {
        if (resultData == "hm failed") {
          setStatus(
            "profile save failed, probably due to duplicate profile name already existing. Errored on " +
              moment(new Date()).format("HH:mm")
          );
        } else {
          setStatus(
            "profile should be saved, on " + moment(new Date()).format("HH:mm")
          );

          //if success, refresh manually so list of associated profiles is updated
          //setManualRefresh(!manualRefresh);
        }

        //callback, update app's manual refresh to trigger reload lists
        cbToggleToManualRefresh();
      })
      .catch((err) => {
        //console.log(err)
        //btnSubmit0.disabled = false;
      });

    //theApp.DoManualRefreshUserPics();
  };

  const submitToDeleteRequestForUser = (
    userId0 = -1,
    associatedName0 = "",
    isWantOrOffer0 = 0,
    activityId0 = -1
  ) => {
    //add one entry at a time, as user adds it
    let theUrl = `${constants.baseURL}/nwrb/deluserreq`;

    fetch(theUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey: "one steps at a time is the wae to victory",
        userId: userId0,
        associatedName: associatedName0,
        isWantOrOffer: isWantOrOffer0,
        activityId: activityId0,
      }),
    })
      .then((response) => response.json())
      .then((resultData) => {
        if (resultData == "del hm failed") {
          setStatus(
            "  del failed, probably due to duplicate profile name already existing. Errored on " +
              moment(new Date()).format("HH:mm")
          );
        } else {
          setStatus(
            "profile should be saved, on " + moment(new Date()).format("HH:mm")
          );

          //if success, refresh manually so list of associated profiles is updated
          //setManualRefresh(!manualRefresh);
        }

        //callback, update app's manual refresh to trigger reload lists
        cbToggleToManualRefresh();
      })
      .catch((err) => {
        //console.log(err)
        //btnSubmit0.disabled = false;
      });

    //theApp.DoManualRefreshUserPics();
  };

  const submitToEditProfile = (formikValues: any) => {
    let theUrl = `${constants.baseURL}/fhp/edit`;

    fetch(theUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey: "must I alwyes edot se[aratery",
        //  guidId: theUser.fhp_guid_id,
        //   loginId: theUser.login_id.toString(),
        offerArr: flattenArrVals(interestArrOffers),
        wantArr: flattenArrVals(interestArrWants),
      }),
    })
      .then((response) => response.json())
      .then((topicsRaw) => {
        setStatus(
          "profile should be edited, on " + moment(new Date()).format("HH:mm")
        );
      })
      .catch((err) => {
        //console.log(err)
        //btnSubmit0.disabled = false;
      });

    //   theApp.DoManualRefreshUserPics();
  };

  const DeleteFHProfile = (theFHPId: string) => {
    fetch(`${constants.baseURL}/fhp/delete`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey: "kkkljiunwua[sk[wfomomomp",
        guidId: theFHPId,
      }),
    })
      .then((response) => response.json())
      .then((topicsRaw) => {
        setStatus("profile should be deleted");
      })
      .catch((err) => {
        //console.log(err)
        //btnSubmit0.disabled = false;
      });

    //theApp.DoManualRefreshUserPics();

    //refresh list
    setManualRefresh(!manualRefresh);
  };

  //don't display site if data is not loaded yet
  if (!isLoaded) {
    return (
      <div className="mx-3">
        <div className="my-1 progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-info"
            role="progressbar"
            style={{ width: "75%" }}
          ></div>
        </div>
        <p>Loading data...</p>
      </div>
    );
  }

  if (
    groupDesc == "" ||
    theUser.secret_username.trim() == "" ||
    theUser.contact_by.trim() == ""
  ) {
    return (
      <div className="mx-3">
        *** You must first 'login' by entering secret login text to associate
        the data to (and a contact method so we can get back to you) ***
      </div>
    );
  }

  return (
    <div className=" ">
      <div style={{ marginLeft: theApp.dispWidth > 750 ? "16px" : "0px" }}>
        <div className="createProfileSection  ">
          <Formik
            enableReinitialize={true}
            initialValues={{
              profileName: "",
              secretUsername: "",
              contactMethod: "",
              associatedName: "",
              chkOffer: [],
              chkWant: [],
              //              starRatingOffer: "",
              //            starRatingWant: defaultStarRating,
              activityNotes: "",
            }}
            validationSchema={Yup.object().shape({
              activityNotes: Yup.string().max(200, "Too Long!"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              //
              //similar to e.preventDefault(), do to prevent params from randomly appearing in url?
              // After handling your submission logic, you can setSubmitting(false) to enable the form again.
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <div className="overflow-x-hidden ml-2" style={{}}>
                <Form>
                  <div className=" " style={{}}>
                    <div>
                      {theUser.id < 1 ? (
                        <div style={{ color: "red" }}>
                          ** You appear to be a new user to this group '
                          {groupId}' . If this is incorrect, please re-enter
                          your secret_username **
                        </div>
                      ) : (
                        ""
                      )}
                      <b style={{}}>List of your want/offer items</b>
                      <br />
                      (that is, you are the point of contact)
                      <br />
                      Entries found:
                      {" " +
                        (interestArrObjOffers.length +
                          interestArrObjWants.length)}{" "}
                      of {NUM_INTEREST_LIMIT}
                      <hr />
                      <b style={{ textAlign: "left" }}>
                        New Need/Want/Request entry:
                      </b>
                      <div className=" ">
                        <b style={{ fontSize: "14px" }}>
                          <Field
                            type="text"
                            name="associatedName"
                            placeholder="(name of person related to activity)"
                            style={{
                              background: "yellow",
                              width: "240px",
                              minHeight: "30px",
                              paddingLeft: "6px",
                              paddingTop: "5px",
                              marginLeft: "0px",
                              marginBottom: "-5px",
                            }}
                          />
                          <br />
                          is{" "}
                          <select
                            style={{
                              minWidth: "240px",
                              height: "45px",
                              marginTop: "2px",
                              marginRight: "12px",
                              paddingLeft: "15px",
                              borderLeft:
                                selectedWantOffer == "want"
                                  ? "8px solid orange"
                                  : "8px solid blue",
                            }}
                            onChange={(e) => {
                              setSelectedWantOffer(e.target.value);
                            }}
                          >
                            <option key="selectWantOfferW" value="want">
                              wanting/searching for from others
                            </option>
                            <option key="selectWantOfferO" value="offer">
                              open to offering to others
                            </option>
                          </select>
                          <br />
                          the activity of{" "}
                          <div className=" ">
                            <select
                              style={{
                                minWidth: "240px",
                                height: "45px",
                                marginTop: "2px",
                                marginRight: "12px",
                                paddingLeft: "15px",
                              }}
                              onChange={(e) => {
                                //tried to set the object itself to this, didn't seem to work, may need to be a string
                                // hence set to two piece info string `${item.id}|${item.name}` ie.  2|Acting
                                setSelectedInterest(e.target.value);
                              }}
                            >
                              <option key="selectItem" value="">
                                - Select a topic -
                              </option>
                              {interestsArr.map((item: any, indx: any) => (
                                <option
                                  key={`interestOffer_${item.name}_${indx}`}
                                  value={`${item.id}|${item.name}`}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className=" "
                            style={{
                              fontSize: "14px",
                              marginLeft: "0px",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              className=" "
                              style={{
                                marginTop: "-3px",
                              }}
                            >
                              {selectedWantOffer == "want"
                                ? "with them having a desired expertise level of"
                                : "wishing to offer it to others who already have an expertise level of "}{" "}
                            </div>

                            <div className=" ">
                              <select
                                style={{
                                  minWidth: "160px",
                                  height: "45px",
                                  marginTop: "2px",
                                  marginRight: "12px",
                                  paddingLeft: "15px",
                                }}
                                onChange={(e) => {
                                  setSelectedExpertise(e.target.value);
                                }}
                                //key="selectLvFrom"
                              >
                                <option value={""}>None</option>
                                <option value={"beginner"}>Beginner</option>
                                <option value={"beginner -> intermediate"}>
                                  Beginner -&gt; Intermediate
                                </option>
                                <option value={"beginner -> advanced"}>
                                  Beginner -&gt; Advanced
                                </option>
                                <option value={"beginner -> expert"}>
                                  Beginner -&gt; Expert
                                </option>
                                <option value={"intermediate"}>
                                  Intermediate
                                </option>
                                <option value={"intermediate -> advanced"}>
                                  Intermediate -&gt; Advanced
                                </option>
                                <option value={"intermediate -> expert"}>
                                  Intermediate -&gt; Expert
                                </option>
                                <option value={"advanced"}>Advanced</option>
                                <option value={"advanced -> expert"}>
                                  Advanced -&gt; Expert
                                </option>
                                <option value={"expert"}>Expert</option>
                              </select>
                            </div>
                          </div>
                          <br />
                          <div
                            className=""
                            style={{
                              justifyContent: "left",
                            }}
                          >
                            <div
                              className={
                                theApp.dispWidth > 750 ? "ml-8" : "ml-2"
                              }
                              style={{
                                maxWidth: "1000px",
                              }}
                            >
                              <b>
                                (Optional) Additional details concerning this
                                activity
                              </b>
                              <br />
                              <i style={{ fontSize: "11px" }}>
                                (not viewable by others until approved by admin)
                              </i>
                              <br />
                              Characters: {values.activityNotes.length} / 200
                              <Field
                                name="activityNotes"
                                type="text"
                                autoComplete=""
                                component="textarea"
                                rows={theApp.dispWidth > 750 ? 3 : 6}
                                style={{ maxWidth: "680px" }}
                                className={
                                  "form-control" +
                                  (errors.activityNotes && touched.activityNotes
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="activityNotes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <br />
                          {selectedInterest != "" &&
                          values.associatedName.trim() != "" &&
                          interestArrOffers.length < NUM_INTEREST_LIMIT &&
                          values.activityNotes.length <= 200 ? (
                            <button
                              type="button"
                              className={
                                selectedWantOffer == "want"
                                  ? "btn btn-warning"
                                  : "btn btn-primary"
                              }
                              style={{
                                height: "48px",
                              }}
                              onClick={() => {
                                // add check, only add item if it doesn't exist already?
                                if (
                                  selectedInterest != "" &&
                                  values.associatedName.trim() != "" &&
                                  interestArrOffers.length < NUM_INTEREST_LIMIT
                                ) {
                                  if (selectedWantOffer == "want") {
                                    //check to ensure this task doesn't already exist for this associated user
                                    let found = 0;
                                    interestArrObjWants.forEach((item: any) => {
                                      if (
                                        item.associatedName ==
                                          values.associatedName &&
                                        item.activityId ==
                                          Piece(selectedInterest, "|", 1, 1) &&
                                        item.isWantOrOffer == 0
                                      )
                                        found = 1;
                                    });

                                    if (!found) {
                                      //set the display string arr
                                      setInterestArrWants((oldStateVals) => [
                                        ...oldStateVals,
                                        "[" +
                                          values.associatedName.trim() +
                                          "] " +
                                          Piece(selectedInterest, "|", 2, 2) +
                                          (selectedExpertise == ""
                                            ? ""
                                            : " (" + selectedExpertise + ")") +
                                          (values.activityNotes.trim() != ""
                                            ? " <" + values.activityNotes + ">"
                                            : ""),
                                      ]);
                                      //set the obj arr, more details, for when saving out
                                      //create new arr with old values, push new value, save to state

                                      setInterestArrObjWants(
                                        (oldStateVals2) => [
                                          ...oldStateVals2,
                                          {
                                            associatedName:
                                              values.associatedName.trim(),
                                            isWantOrOffer: 0,
                                            activityId: Piece(
                                              selectedInterest,
                                              "|",
                                              1,
                                              1
                                            ),
                                            activityName: Piece(
                                              selectedInterest,
                                              "|",
                                              2,
                                              2
                                            ),
                                            activityNotes: values.activityNotes,
                                            expertiseLevel: selectedExpertise,
                                          },
                                        ]
                                      );

                                      //save to server
                                      submitToAddRequestForUser(
                                        values,
                                        theUser.id,
                                        0
                                      );
                                    }
                                  } else {
                                    //check to ensure this task doesn't already exist for this associated user
                                    let found = 0;

                                    interestArrObjOffers.forEach(
                                      (item: any) => {
                                        if (
                                          item.associatedName ==
                                            values.associatedName &&
                                          item.activityId ==
                                            Piece(
                                              selectedInterest,
                                              "|",
                                              1,
                                              1
                                            ) &&
                                          item.isWantOrOffer == 1
                                        )
                                          found = 1;
                                      }
                                    );

                                    if (!found) {
                                      setInterestArrOffers((oldStateVals3) => [
                                        ...oldStateVals3,
                                        "[" +
                                          values.associatedName.trim() +
                                          "] " +
                                          Piece(selectedInterest, "|", 2, 2) +
                                          (selectedExpertise == ""
                                            ? ""
                                            : " (" + selectedExpertise + ")") +
                                          (values.activityNotes.trim() != ""
                                            ? " <" + values.activityNotes + ">"
                                            : ""),
                                      ]);
                                      //set the obj
                                      setInterestArrObjOffers(
                                        (oldStateVals4) => [
                                          ...oldStateVals4,
                                          {
                                            associatedName:
                                              values.associatedName.trim(),
                                            isWantOrOffer: 1,
                                            activityId: Piece(
                                              selectedInterest,
                                              "|",
                                              1,
                                              1
                                            ),
                                            activityName: Piece(
                                              selectedInterest,
                                              "|",
                                              2,
                                              2
                                            ),
                                            activityNotes: values.activityNotes,
                                            expertiseLevel: selectedExpertise,
                                          },
                                        ]
                                      );

                                      //save to server
                                      submitToAddRequestForUser(
                                        values,
                                        theUser.id,
                                        1
                                      );
                                    }
                                  }
                                }
                                //e.persist();
                              }}
                            >
                              <i className="bi bi-plus-square mr-2"></i> Add
                              {selectedWantOffer == "want"
                                ? " request"
                                : " offer"}{" "}
                              to list
                            </button>
                          ) : (
                            "[Cannot add entry: missing activity or name to associate to activity, or exceeded max entries, or activity notes too long]"
                          )}
                        </b>
                        <br />
                        <div
                          style={{
                            borderTop: "4px solid gray",
                            margin: "10px 35px",
                          }}
                        />
                        <i>Your list of saved Wants:</i>
                        <div
                          role="group"
                          aria-labelledby="checkbox-group"
                          className={`${theApp.dispWidth > 750 ? "mx-10" : ""}`}
                          style={{ border: "2px solid blue" }}
                        >
                          {
                            // use arr to generate list of checkbox, self interest fields, ORDER IS IMPORTANT IF ADD NEW ENTRIES!!! Once finalized, don't change order of old entries!!
                            // TO DO, highlight whole card bg color change if selected, only allow up to 10 items to be selected, save output as bitwise 000100111000, will make it much easier to add. substring, char at pos, sum all. May be tricky to load here though
                            interestArrWants.map(
                              (theInterest, interestIndx: any) => {
                                return (
                                  <div
                                    className="w-full"
                                    key={`interestWants${interestIndx}`}
                                    style={{}}
                                  >
                                    <div
                                      className="flex"
                                      style={{ minHeight: "50px" }}
                                    >
                                      <div
                                        className="taskItemText w-full text-left mt-[-1px] mb-[-1px] px-2 pb-1"
                                        style={{
                                          border: "3px solid black",
                                          borderLeft: "8px solid orange",
                                        }}
                                      >
                                        {theInterest}
                                      </div>

                                      <button
                                        type="button"
                                        className="btn btn-danger pl-3"
                                        style={{
                                          border: "2px solid black",
                                          maxWidth: "80px",
                                          minWidth: "70px",
                                        }}
                                        onClick={() => {
                                          // make a separate copy of the array
                                          var tmpArray = [...interestArrWants];
                                          var index =
                                            tmpArray.indexOf(theInterest);
                                          if (index !== -1) {
                                            //remove item at this index
                                            tmpArray.splice(index, 1);
                                            setInterestArrWants(tmpArray);
                                          }

                                          //also do it for data arr
                                          var tmpArray2 = [
                                            ...interestArrObjWants,
                                          ];

                                          //delete from server, pull data from deleted entry before deleting it
                                          submitToDeleteRequestForUser(
                                            theUser.id,
                                            tmpArray2[index].associatedName,
                                            0,
                                            tmpArray2[index].activityId
                                          );

                                          //assume same index as other array
                                          //                                            var index =
                                          //                                            tmpArray2.indexOf(theInterest);
                                          if (index !== -1) {
                                            //remove item at this index
                                            tmpArray2.splice(index, 1);
                                            setInterestArrObjWants(tmpArray2);
                                          }
                                        }}
                                      >
                                        <i
                                          className="bi bi-x-circle-fill row"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "-10px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontSize: "14px",
                                              marginTop: "-1px",
                                              marginLeft: "2px",
                                            }}
                                          >
                                            Delete
                                          </div>{" "}
                                        </i>
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          }
                        </div>
                        <br />

                        <i>Your list of saved Offers:</i>
                        <div
                          role="group"
                          aria-labelledby="checkbox-group"
                          className={`${theApp.dispWidth > 750 ? "mx-10" : ""}`}
                          style={{ border: "2px solid blue" }}
                        >
                          {
                            // use arr to generate list of checkbox, self interest fields, ORDER IS IMPORTANT IF ADD NEW ENTRIES!!! Once finalized, don't change order of old entries!!
                            // TO DO, highlight whole card bg color change if selected, only allow up to 10 items to be selected, save output as bitwise 000100111000, will make it much easier to add. substring, char at pos, sum all. May be tricky to load here though
                            interestArrOffers.map(
                              (theInterest, interestIndx: any) => {
                                return (
                                  <div
                                    className="w-full"
                                    key={`interestOffers${interestIndx}`}
                                  >
                                    <div
                                      className="flex"
                                      style={{ minHeight: "50px" }}
                                    >
                                      <div
                                        className="taskItemText w-full text-left mt-[-1px] mb-[-1px] px-2 pb-1"
                                        style={{
                                          border: "3px solid black",
                                          borderLeft: "8px solid blue",
                                        }}
                                      >
                                        {theInterest}
                                      </div>

                                      <button
                                        type="button"
                                        className="btn btn-danger pl-3"
                                        style={{
                                          border: "2px solid black",
                                          maxWidth: "80px",
                                          minWidth: "70px",
                                        }}
                                        onClick={() => {
                                          // make a separate copy of the array
                                          var tmpArray = [...interestArrOffers];
                                          var index =
                                            tmpArray.indexOf(theInterest);
                                          if (index !== -1) {
                                            //remove item at this index
                                            tmpArray.splice(index, 1);
                                            setInterestArrOffers(tmpArray);
                                          }

                                          //also do it for data arr

                                          var tmpArray2 = [
                                            ...interestArrObjOffers,
                                          ];

                                          //delete from server, pull data from deleted entry before deleting it
                                          submitToDeleteRequestForUser(
                                            theUser.id,
                                            tmpArray2[index].associatedName,
                                            1,
                                            tmpArray2[index].activityId
                                          );

                                          //assume same index as other array
                                          //                                            var index =
                                          //                                            tmpArray2.indexOf(theInterest);
                                          if (index !== -1) {
                                            //remove item at this index
                                            tmpArray2.splice(index, 1);
                                            setInterestArrObjOffers(tmpArray2);
                                          }
                                        }}
                                      >
                                        <i
                                          className="bi bi-x-circle-fill row"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "-10px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontSize: "14px",
                                              marginTop: "-1px",
                                              marginLeft: "2px",
                                            }}
                                          >
                                            Delete
                                          </div>{" "}
                                        </i>
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          }
                        </div>
                        <br />
                        <div
                          style={{
                            borderTop: "4px solid gray",
                            margin: "10px 35px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FriendMatchCreateMobile;
